import {createListenerMiddleware} from '@reduxjs/toolkit';

import {TiMeLogger} from 'features/logger';

import {sendPostNotification} from '../actions/send_post_notification';

export const sendPostNotificationLoggingListener = createListenerMiddleware();

// eslint-disable-next-line no-process-env
if (process.env.NODE_ENV !== 'test') {
    const logger = TiMeLogger.child({
        name: 'sendPostNotification',
    });

    sendPostNotificationLoggingListener.startListening({
        actionCreator: sendPostNotification.pending,
        effect: (action) => {
            logger.debug('pending', action.meta.arg);
        },
    });

    sendPostNotificationLoggingListener.startListening({
        actionCreator: sendPostNotification.fulfilled,
        effect: (action) => {
            logger.debug('fullfiled', action.meta.arg);
        },
    });

    sendPostNotificationLoggingListener.startListening({
        actionCreator: sendPostNotification.rejected,
        effect: (action) => {
            logger.warn('rejected', action.payload, action.meta.arg);
        },
    });
}

export const sendPostNotificationLoggingMiddleware = sendPostNotificationLoggingListener.middleware;

