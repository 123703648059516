import {createAsyncThunk} from '@reduxjs/toolkit';

import type {AxiosError} from 'axios';

import type {AppDispatch} from 'stores/redux_store';
import {Client4} from 'mattermost-redux/client';
import {forceLogoutIfNecessaryThunkAxios} from 'mattermost-redux/actions/helpers';
import {logAxiosError} from 'mattermost-redux/actions/errors';

type Payload = {
    userId: string;
};

export const fetchProfile = createAsyncThunk(
    'profiles/api/fetchProfile',
    async (payload: Payload, thunkAPI) => {
        const {userId} = payload;
        const dispatch = thunkAPI.dispatch as AppDispatch;

        try {
            return await Client4.doFetch(`${Client4.getUsersRoute()}/${userId}/profile`, {method: 'get'});
        } catch (e: unknown) {
            const error = e as AxiosError;

            //@TODO: выяснить почему нет статуса
            if (error.status === 404 || error.response?.status === 404) {
                return {
                    user_id: userId,
                };
            }

            dispatch(forceLogoutIfNecessaryThunkAxios(error));
            dispatch(logAxiosError(error));

            throw error;
        }
    },
);
