import axios, {AxiosError, AxiosRequestConfig, AxiosInstance, CreateAxiosDefaults} from 'axios';
import FormData from 'form-data';

import {exponentialDelay, isNetworkOrIdempotentRequestError, addRetryInterceptors} from './retry';
import {TIMEOUT} from './constants';
import {addHttpErrorInterceptor} from './http_error_interceptor';

// eslint-disable-next-line no-process-env
const isTestEnv = process.env.NODE_ENV === 'test';

const ADAPTER_TIMEOUT = isTestEnv ? 0 : TIMEOUT;

const config = {
    timeout: ADAPTER_TIMEOUT,
    responseType: 'json',
    responseEncoding: 'utf8',
    xsrfCookieName: 'MMCSRF',
    xsrfHeaderName: 'x-csrf-token',
    headers: {'x-requested-with': 'XMLHttpRequest'},
    env: {
        FormData,
    },
} as CreateAxiosDefaults;

const baseApiClient = axios.create(config);

if (!isTestEnv) {
    addRetryInterceptors(baseApiClient as unknown as AxiosInstance, {
        retries: 0,
        retryCondition: isNetworkOrIdempotentRequestError,
        retryDelay: (count) => exponentialDelay(count, 300),
    });
}
addHttpErrorInterceptor(baseApiClient);

export const TimeApiClient = baseApiClient;

export type TimeApiClientOptions<D = any> = AxiosRequestConfig<D>;
export type TimeApiClientError<T = unknown, D = any> = AxiosError<T, D>;
