import {createSlice} from '@reduxjs/toolkit';

import {User} from 'features/users';
import {getProfile} from '../actions/get_profile';
import {updateProfile} from '../actions/update_profile';
import {Profile} from '../types/profile';

export type ProfilesState = Record<User['id'], Profile>;

const initialState: ProfilesState = {};

export const profilesSlice = createSlice({
    name: 'profiles',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getProfile.fulfilled, (state, action) => {
            state[action.meta.arg] = action.payload;
        });
        builder.addCase(updateProfile.fulfilled, (state, action) => {
            state[action.meta.arg.user_id] = action.payload;
        });
    },
});
