import {createAsyncThunk} from '@reduxjs/toolkit';

import {getCurrentUser} from 'mattermost-redux/selectors/entities/common';

import type {GlobalState} from 'types/store';

import type {User} from '../types/user';

export const receivedUsers = createAsyncThunk('users/actions/receivedUsers', (users: User[], thunkAPI) => {
    const state = thunkAPI.getState() as GlobalState;

    const currentUser = getCurrentUser(state);

    return users.map((user) => {
        if (user.id === currentUser?.id) {
            return {...currentUser, ...user};
        }

        return user;
    });
});
