import React from 'react';
import {FocusScope} from '@react-aria/focus';
import {mergeProps} from '@react-aria/utils';
import {
    DismissButton,
    OverlayContainer,
    useOverlay,
    useOverlayPosition,
    useOverlayTrigger,
} from '@react-aria/overlays';
import {OverlayTriggerState, useOverlayTriggerState} from '@react-stately/overlays';
import type {AriaPositionProps} from '@react-aria/overlays';
import type {OverlayTriggerProps} from '@react-types/overlays';
import type {FocusableElement} from '@react-types/shared';
import type {AriaButtonProps} from '@react-aria/button';

export const Popover = React.forwardRef<Element, any>(
    ({children, isOpen, onClose, style, ...otherProps}, passedRef) => {
        const innerRef = React.createRef<Element>();
        const ref = (passedRef || innerRef) as React.RefObject<FocusableElement>;

        const {overlayProps, underlayProps} = useOverlay(
            {
                onClose,
                isOpen,
                isDismissable: true,
                shouldCloseOnBlur: true,
            },
            ref,
        );

        return (
            <FocusScope restoreFocus={true}>
                <div
                    {...mergeProps(overlayProps, otherProps)}
                    ref={passedRef}
                    style={{...style, visibility: isOpen ? 'visible' : 'hidden'}}
                >
                    {React.cloneElement(children, underlayProps)}
                    <DismissButton onDismiss={onClose} />
                </div>
            </FocusScope>
        );
    },
);

type Props = {
    triggerNode?: Element;
    state?: OverlayTriggerState;
    renderTriggerElement?: (
        triggerProps: AriaButtonProps,
        ref: React.RefObject<HTMLButtonElement>,
        state: OverlayTriggerState
    ) => React.ReactNode;
} & OverlayTriggerProps &
Omit<Partial<AriaPositionProps>, 'targetRef' | 'overlayRef' | 'isOpen'>;

export function PopoverOverlay(props: React.PropsWithChildren<Props>) {
    const {
        triggerNode,
        maxHeight,
        placement,
        containerPadding = 0,
        offset = 5,
        crossOffset,
        shouldFlip = true,
        renderTriggerElement,
        state: outsideState,
        ...overlayTriggerProps
    } = props;
    const innerState = useOverlayTriggerState(overlayTriggerProps);
    const state = outsideState || innerState;
    const overlayRef = React.useRef<HTMLDivElement>(null);

    const triggerRef = React.useRef<HTMLButtonElement>(null);

    const ref = triggerNode ? {
        current: triggerNode,
    } : triggerRef;

    const {overlayProps, triggerProps} = useOverlayTrigger({type: 'menu'}, state, ref);

    const {overlayProps: positionProps} = useOverlayPosition({
        targetRef: ref,
        overlayRef,
        placement,
        containerPadding,
        offset,
        isOpen: state.isOpen,
        crossOffset,
        shouldFlip,
        maxHeight,
    });

    return (
        <>
            {renderTriggerElement?.(triggerProps, triggerRef, state)}
            <OverlayContainer>
                {state.isOpen ? (
                    <Popover {...overlayProps} {...positionProps} ref={overlayRef} isOpen={state.isOpen} onClose={state.close}>
                        {props.children}
                    </Popover>
                ) : null}
            </OverlayContainer>
        </>
    );
}
