import {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

import CopyIcon from 'components/widgets/icons/copy_icon';
import {copyToClipboard} from 'utils/utils';
import {reportMessageToSentry} from 'utils/sentry';

const CopyButton = styled.button`
    width: 28px;
    height: 28px;
    color: rgba(var(--center-channel-color-rgb), 0.56);
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    border: none;
    transition: background 0.3s;

    &.compact {
         width: 24px;
         height: 24px;
         margin: -8px 0;
    }

    &:hover {
        background: rgba(var(--center-channel-color-rgb), 0.08);
    }

    &.success {
        background: var(--denim-status-online);
        color: var(--button-color);
    }

    .time-list-item & {
        visibility: hidden;
    }

    .time-list-item:hover & {
        visibility: visible;
    }
`;

const verifyValueAndReport = (value: string) => {
    if (typeof value !== 'string') {
        return reportMessageToSentry(`CopyToClipboardButton: ${value} is not valid string to copy`);
    }

    if (value.length === 0) {
        return reportMessageToSentry(`CopyToClipboardButton: nothing to copy due to ${value}`);
    }
};

const CopyToClipboardButton = ({value, compact}: {value: string; compact?: boolean}) => {
    const [copied, setCopied] = useState(false);
    const timer = useRef<any>();

    useEffect(() => {
        return function clearTimeoutOnUnmount() {
            if (timer.current) {
                clearTimeout(timer.current);
            }
        };
    }, []);

    const copy = async () => {
        verifyValueAndReport(value);
        await copyToClipboard(value);
        setCopied(true);
        clearTimeout(timer.current);
        timer.current = setTimeout(() => setCopied(false), 1000);
    };

    return (
        <CopyButton
            className={classNames({success: copied, compact})}
            onClick={copy}
        >
            {copied ? <i className={'icon icon-check'} /> : <CopyIcon />}
        </CopyButton>
    );
};

export default CopyToClipboardButton;
