import type {Middleware} from '@reduxjs/toolkit';

import {draftsReducer, draftsReducerName, middlewares as draftsFeatureMiddleware} from './drafts';
// eslint-disable-next-line no-duplicate-imports
import type {DraftsReducerState} from './drafts';
import {sidebarForTeamLoadingSlice} from './sidebar';
import {channelPermissionsSlice, readOnlyChannelsSlice} from './channels';
import {deletePostsOnChannelSwitchMiddleware} from './posts/middlewares/delete_posts_on_channel_switch';
import {profilesSlice} from './profiles';
import {activitySlice} from './activity/slices/activity';
import {userThreadsSlice} from './threads/slices/user_threads';
import {userThreadsListenerMiddleware} from './threads/middlewares/user_threads_listener_middleware';

import type {ProfilesState} from './profiles';
import type {ReadOnlyChannelsState} from './channels';
import {appVisibilitySlice} from './app_activity/slices/app_visibility_slice';
import {sendPostNotificationLoggingMiddleware} from './notifications/middlewares/send_post_notification_logging_middleware';
import {fatalErrorsReducerName, fatalErrorsReducer, FatalErrorsState} from './fatalErrors';

import type {ActivityState} from './activity/slices/activity';

export const featuresReducers = {
    [fatalErrorsReducerName]: fatalErrorsReducer,
    [draftsReducerName]: draftsReducer,
    [sidebarForTeamLoadingSlice.name]: sidebarForTeamLoadingSlice.reducer,
    [channelPermissionsSlice.name]: channelPermissionsSlice.reducer,
    [readOnlyChannelsSlice.name]: readOnlyChannelsSlice.reducer,
    [profilesSlice.name]: profilesSlice.reducer,
    [appVisibilitySlice.name]: appVisibilitySlice.reducer,
    [activitySlice.name]: activitySlice.reducer,
    [userThreadsSlice.name]: userThreadsSlice.reducer,
};

type SidebarForTeamLoadingState = ReturnType<typeof sidebarForTeamLoadingSlice['getInitialState']>;
type ChannelPermissionsState = ReturnType<typeof channelPermissionsSlice['getInitialState']>;
type AppVisibilityState = ReturnType<typeof appVisibilitySlice['getInitialState']>;
type UserThreadsState = ReturnType<typeof userThreadsSlice['getInitialState']>;

export type FeaturesReducersState = {
    [fatalErrorsReducerName]: FatalErrorsState;
    [draftsReducerName]: DraftsReducerState;
    [sidebarForTeamLoadingSlice.name]: SidebarForTeamLoadingState;
    [channelPermissionsSlice.name]: ChannelPermissionsState;
    [readOnlyChannelsSlice.name]: ReadOnlyChannelsState;
    [profilesSlice.name]: ProfilesState;
    [appVisibilitySlice.name]: AppVisibilityState;
    [userThreadsSlice.name]: UserThreadsState;
    [activitySlice.name]: ActivityState;
};

export const featuresMiddlewares = ([] as Middleware[]).
    concat(draftsFeatureMiddleware).
    concat(deletePostsOnChannelSwitchMiddleware).
    concat(sendPostNotificationLoggingMiddleware).
    concat(userThreadsListenerMiddleware);
