import {createAsyncThunk} from '@reduxjs/toolkit';

import {getCurrentUser} from 'mattermost-redux/selectors/entities/common';

import {GlobalState} from 'types/store';

import {isUser} from 'mattermost-redux/utils/user_utils';

export const receivedUser = createAsyncThunk('users/actions/receivedUser', (user: object, thunkApi) => {
    const state = thunkApi.getState() as GlobalState;

    const currentUser = getCurrentUser(state);

    if (!isUser(user)) {
        return thunkApi.rejectWithValue('Incorrect user data');
    }

    if (user.id === currentUser?.id) {
        return thunkApi.fulfillWithValue({
            ...currentUser,
            ...user,
        });
    }

    return thunkApi.fulfillWithValue(user);
});
