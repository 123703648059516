import {createAsyncThunk} from '@reduxjs/toolkit';

import type {AxiosError} from 'axios';

import {Team} from '@mattermost/types/teams';

import {User} from 'features/users';

import type {AppDispatch} from 'stores/redux_store';
import {getUserThread} from '../api/get_user_thread';

import type {ClientThread} from '../types/extended';

import {reportMessageToSentry} from 'utils/sentry';

import {handleThreadArrived} from './handle_thread_arrived';
import {changeThreadFollow} from './change_thread_follow';

type Payload = {
    userId: User['id'];
    teamId: Team['id'];
    threadId: ClientThread['id'];
    extended?: boolean;
};

export const fetchUserThread = createAsyncThunk(
    'threads/actions/fetchUserThread',
    async (payload: Payload, thunkAPI) => {
        const {teamId, threadId, userId, extended = true} = payload;
        const dispatch = thunkAPI.dispatch as AppDispatch;

        try {
            const thread = await dispatch(
                getUserThread({
                    teamId,
                    threadId,
                    userId,
                    extended,
                }),
            ).unwrap();

            await dispatch(
                handleThreadArrived({
                    teamId,
                    thread,
                }),
            );

            /**
             * getUserThread может отдать успешно тред только в случае
             * если пользователь является участником треда и подписан на него
             * поэтому мы здесь смело можем пометить что он следует за этим тредом
             */
            dispatch(changeThreadFollow({
                following: true,
                threadId,
            }));

            return thread;
            // eslint-disable-next-line no-empty
        } catch (e: unknown) {
            const error = e as AxiosError;
            if (error.status === 404) {
                return;
            }

            reportMessageToSentry(`Encountered error while fetchUserThread "${error.message}"`);
        }
    },
);
