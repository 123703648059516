export const DEFAULT_CHANNEL = 'town-square';

export enum ChannelType {
    DM = 'D',
    PUBLIC = 'O',
    PRIVATE = 'P',
    GM = 'G',
}

export enum ChannelCategoryType {
    FAVORITES = 'favorites',
    CHANNELS = 'channels',
    DIRECT_MESSAGES = 'direct_messages',
    CUSTOM = 'custom',
}
