import {createAsyncThunk} from '@reduxjs/toolkit';

import type {Channel} from '@mattermost/types/channels';
import type {GlobalState} from 'types/store';
import type {AppDispatch} from 'stores/redux_store';
import {fetchUsersByIds} from 'features/users/actions/fetch_users_by_ids';
import {makeGetChannel} from 'mattermost-redux/selectors/entities/channels';
import {asyncDelay} from 'utils/asyncDelay';

import type {Payload} from './types';
import {TIMEOUT} from './constants';
import {shouldSendNotification} from './should_send_notification';
import {sendNotification} from './send_notification';

const getChannel = makeGetChannel();

export const sendPostNotification = createAsyncThunk(
    'notifications/actions/sendPostNotification',
    async (payload: Payload, thunkAPI) => {
        const state = thunkAPI.getState() as GlobalState;
        const dispatch = thunkAPI.dispatch as AppDispatch;
        const {post, meta} = payload;

        const postChannel = getChannel(state, {id: post.channel_id}) as Channel | undefined;

        const shouldSend = shouldSendNotification(state, post, meta, postChannel);

        if (!shouldSend.send) {
            return thunkAPI.rejectWithValue(shouldSend.reason);
        }

        /**
         * Если у нас нет фоллбэка от бэкенда, то нам нужен пользователь
         * Но мы не хотим сильно задерживать показ нотификации
         * поэтому ждем совсем немного
         */
        if (!meta.sender_name) {
            await Promise.race([
                dispatch(
                    fetchUsersByIds({
                        userIds: [post.user_id],
                    }),
                ),
                asyncDelay(TIMEOUT),
            ]);
        }

        const updatedState = thunkAPI.getState() as GlobalState;

        return sendNotification(updatedState, post, postChannel, meta);
    },
);

export const sendPostsNotification = createAsyncThunk(
    'notifications/actions/sendPostsNotification',
    async (payload: Payload[], thunkAPI) => {
        const state = thunkAPI.getState() as GlobalState;
        const dispatch = thunkAPI.dispatch as AppDispatch;

        const postsWithMeta = payload.filter(({post, meta}) => {
            const channel = getChannel(state, {id: post.channel_id}) as Channel | undefined;

            return shouldSendNotification(state, post, meta, channel).send;
        });

        if (!postsWithMeta.length) {
            return;
        }

        const userIdsToLoad = postsWithMeta.filter((p) => !p.meta.sender_name).map((p) => p.post.user_id);

        /**
         * Если у нас нет фоллбэка от бэкенда, то нам нужен пользователь
         * Но мы не хотим сильно задерживать показ нотификации
         * поэтому ждем совсем немного
         */
        if (userIdsToLoad.length) {
            await Promise.race([
                dispatch(
                    fetchUsersByIds({
                        userIds: userIdsToLoad,
                    }),
                ),
                asyncDelay(TIMEOUT),
            ]);
        }

        const updatedState = thunkAPI.getState() as GlobalState;

        for (const {post, meta} of postsWithMeta) {
            const channel = getChannel(state, {id: post.channel_id}) as Channel | undefined;

            sendNotification(updatedState, post, channel, meta);
        }
    },
);
