import React from 'react';
import classNames from 'classnames';
import {TUserStatus} from '@mattermost/compass-components/shared';

type Props = {
    className?: string;
    status?: TUserStatus;
};

export default function StatusIcon({status, className = ''}: Props) {
    if (!status) {
        return null;
    }

    return <span className={classNames('status-icon', 'status-' + status, className)} />;
}
