/* eslint-disable consistent-return */
import * as Sentry from '@sentry/react';
import {Breadcrumb} from '@sentry/react';
import type {User, CaptureContext, Severity} from '@sentry/types';

import type {TelemetryHandler} from 'packages/mattermost-redux/src/client/telemetry';

// eslint-disable-next-line no-process-env
const sentryEnabled = process.env.SENTRY_ENABLED;

export function setSentryUser(user: User) {
    if (!sentryEnabled) {
        return;
    }
    return Sentry.setUser(user);
}

export function setSentryServer(serverUrl: string) {
    if (!sentryEnabled) {
        return;
    }
    return Sentry.setExtra('selectedServerUrl', serverUrl);
}

export function reportErrorToSentry<Exception>(exception: Exception, captureContext?: CaptureContext) {
    if (!sentryEnabled) {
        return;
    }

    return Sentry.captureException(exception, captureContext);
}

export function reportMessageToSentry(exception: string, captureContext?: CaptureContext | Severity) {
    if (!sentryEnabled) {
        return;
    }
    return Sentry.captureMessage(exception, captureContext);
}

export function addBreadcrumbToSentry(breadcrumb: Breadcrumb) {
    if (!sentryEnabled) {
        return;
    }
    return Sentry.addBreadcrumb(breadcrumb);
}

export class SentryBreadcrumbsTelemetry implements TelemetryHandler {
    pageVisited = (userId: string, userRoles: string, category: string, name: string) => {
        addBreadcrumbToSentry({
            category,
            type: 'navigation',
            level: 'info' as Severity.Info,
            timestamp: Date.now(),
            message: name,
            data: {
                userRoles,
                userId,
            },
        });
    }
    trackEvent = (userId: string, userRoles: string, category: string, event: string, props = {}) => {
        addBreadcrumbToSentry({
            category,
            type: 'info',
            message: event,
            level: 'info' as Severity.Info,
            timestamp: Date.now(),
            data: {
                userRoles,
                userId,
                ...props,
            },
        });
    }
}
