import {createAsyncThunk} from '@reduxjs/toolkit';

import {getCurrentUserId, getUsers} from 'mattermost-redux/selectors/entities/common';
import {batchAsyncDebounce} from 'mattermost-redux/utils/debounce';
import type {AppDispatch} from 'stores/redux_store';

import type {GlobalState} from 'types/store';
import {getUsersByIds} from '../api/get_users_by_ids';
import type {User} from '../types/user';

import {receivedUsers} from './received_users';

type Payload = {userIds: Array<User['id']>; since?: number};

export const fetchUsersByIds = createAsyncThunk('users/actions/fetchUsersByIds', async (payload: Payload, thunkAPI) => {
    const {userIds, since} = payload;

    if (!userIds.length) {
        return;
    }

    const state = thunkAPI.getState() as GlobalState;

    const dispatch = thunkAPI.dispatch as AppDispatch;
    const currentUserId = getCurrentUserId(state);

    const userIdsSet = new Set(userIds);
    userIdsSet.delete(currentUserId);

    if (!userIdsSet.size) {
        return;
    }

    const uniqUsers = Array.from(userIdsSet);

    const allUsers = getUsers(state);

    const userIdsToRequest = uniqUsers.filter((userId) => !allUsers[userId]?.username);

    if (!userIdsToRequest.length) {
        return;
    }

    const users = await dispatch(
        getUsersByIds({
            userIds: userIdsToRequest,
            since,
        }),
    ).unwrap();

    if (users.length) {
        await dispatch(receivedUsers(users));
    }
});

const fetchUsersByUserIdsList = (userIdsList: string[]) => (dispatch: AppDispatch) =>
    dispatch(
        fetchUsersByIds({
            userIds: userIdsList,
        }),
    );

export const fetchUsersByIdsDebounced = batchAsyncDebounce(fetchUsersByUserIdsList);
