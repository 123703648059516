import {createAsyncThunk} from '@reduxjs/toolkit';

import type {AxiosError} from 'axios';

import {isNewProfileEnabled} from 'mattermost-redux/selectors/entities/preferences';

import {AppDispatch} from 'stores/redux_store';
import {GlobalState} from 'types/store';
import {fetchProfile} from '../api/fetch_profile';
import {Profile} from '../types/profile';

export const getProfile = createAsyncThunk(
    'profiles/actions/getProfile',
    async (userId: Profile['user_id'], thunkAPI) => {
        const dispatch = thunkAPI.dispatch as AppDispatch;
        try {
            const profile = await dispatch(fetchProfile({userId})).unwrap();

            return profile;
        } catch (e: unknown) {
            const error = e as AxiosError;

            // Если у пользователя нет профиля, это не повод выбрасывать ошибку
            if (error.status === 404) {
                return {
                    user_id: userId,
                };
            }

            return thunkAPI.rejectWithValue('');
        }
    },
    {
        condition: (_userId, thunkAPI) => {
            return isNewProfileEnabled(thunkAPI.getState() as GlobalState);
        },
    },
);
