/* eslint-disable react/jsx-no-literals */
import React, {useEffect} from 'react';
import styled from 'styled-components';

import {useStatist} from '@time-webkit/statist';

import store from '../../stores/redux_store';

import {isDesktopApp} from 'utils/user_agent';
import {getReportAProblemLink} from 'mattermost-redux/selectors/entities/teams';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    height: 100vh;
    background-color: var(--center-channel-bg, white);
`;

const Title = styled.h1`
    font-family: 'Lato';
    font-style: normal;
    font-size: 28px;
    line-height: 36px;

    display: flex;
    align-items: center;
    max-width: 570px;

    color: var(--center-channel-color, black);
    margin-bottom: 12px;
`;

const Body = styled.h1`
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    text-align: center;

    & p {
        margin-bottom: 12px;
    }

    color: var(--center-channel-color, black);
`;

const MainMessage = styled.div`
    max-width: 570px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
`;

const Btn = styled.button`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    font-family: 'Lato';
    font-style: normal;
    font-size: 18px;
    line-height: 18px;

    height: 48px;
    padding-left: 10px;
    padding-right: 10px;

    /* Denim/Button BG */

    background: var(--button-bg, #1c58d9);
    color: var(--button-color, white);
    border: none;
    border-radius: 4px;
    box-sizing: border-box;

    margin-top: 12px;
`;

const Loader = styled.span`
    width: 26px;
    height: 26px;
    border: 0.2em solid currentcolor;
    border-bottom-color: transparent;
    border-radius: 50%;
    animation: 1s loader-03 linear infinite;
    position: relative;

    @keyframes loader-03 {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;

const ReportAProblem = styled.a`
    font-family: 'Lato';
    font-style: normal;
    font-size: 14px;
    line-height: 14px;
    /* identical to box height, or 100% */

    display: flex;
    align-items: center;

    color: var(--center-channel-color-56, rgba(63, 67, 80, 0.56));

    margin-top: auto;
    margin-bottom: 40px;
`;

const ROOT = '/';

const NAVIGATION_TIMEOUT = 60 * 1000; // 60 sec
const INACTIVE_TIMEOUT = 15 * 1000; // 15 sec

function navigateToRoot() {
    window.location.href = ROOT;
}

export const ErrorFallback = () => {
    const [loading, setLoading] = React.useState(false);
    const clickedState = React.useRef(false);
    const {sendToStatist} = useStatist();

    const reportAProblemLink = getReportAProblemLink(store.getState());

    // Пока редиректим на главную, во избежании повторения косяка
    const handleRefresh = () => {
        clickedState.current = true;

        // Лоадер для интерактивности и ощущения прогресса
        setLoading(true);
        navigateToRoot();
    };

    const isApp = React.useMemo(() => isDesktopApp(), []);

    useEffect(() => {
        let loadingTimeout: NodeJS.Timeout;

        /**
         * Если спустя минуту мы не ушли со страницы
         * то сбрасываем состояние загрузки
         */
        if (loading) {
            loadingTimeout = setTimeout(() => {
                clickedState.current = false;
                setLoading(false);
            }, NAVIGATION_TIMEOUT);
        }

        return () => {
            if (loadingTimeout) {
                clearTimeout(loadingTimeout);
            }
        };
    }, [loading]);

    useEffect(() => {
        sendToStatist('app.errorScreen');
    }, [sendToStatist]);

    useEffect(() => {
        /**
         * Если спустя 15 секунд ничего не сделали - рефрешим сами
         *
         * Большая вероятность что пользователь не видит этот экран
         * А мы не хотим чтобы он это обнаружил через час
         */
        const forceNavigationTimeout = setTimeout(() => {
            if (!clickedState.current) {
                navigateToRoot();
            }
        }, INACTIVE_TIMEOUT);

        return () => {
            clearTimeout(forceNavigationTimeout);
        };
    }, []);

    const whatToUpdate = isApp ? 'приложение' : 'страницу';

    return (
        <Wrapper>
            <MainMessage>
                <Title>Что-то пошло не так...</Title>
                <Body>
                    <p>
                        Попробуйте перезагрузить {whatToUpdate}. Если не помогло – выполните перезагрузку с очисткой
                        кэша <i className='fa fa-windows' /> Ctrl+Shift+R или <i className='fa fa-apple' /> Cmd+Shift+R
                    </p>
                </Body>
                <Btn onClick={handleRefresh}>{loading ? <Loader /> : 'Перезагрузить'}</Btn>
            </MainMessage>
            {
                reportAProblemLink ? (
                    <ReportAProblem
                        href={reportAProblemLink}
                        target='_blank'
                    >
                        Оставить запрос в техподдержку
                    </ReportAProblem>
                ) : null
            }
        </Wrapper>
    );
};
