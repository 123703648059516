/* eslint-disable no-process-env */
import type {StatistEventClient} from '@tinkoff/statist-browser-typed-client-itsa.corporatemessenger.clientv1.web.events';

declare global {
    interface Window {
        statistClient?: StatistEventClient;
    }
}

import {StatistClient} from '@tinkoff/statist-browser-typed-client-itsa.corporatemessenger.clientv1.web.events';

import {isDesktopApp} from 'utils/user_agent';

import {updateUserPropsInStatist} from './methods';

export const initialize = () => {
    if (!process.env.STATIST_ENABLED || window.statistClient) {
        return;
    }

    const isProduction = process.env.NODE_ENV === 'production';

    window.statistClient = new StatistClient({
        environment: isProduction ? 'prod' : 'test',
        clientParameters: {
            client: process.env.STATIST_CLIENT,
            clientVersion: process.env.RELEASE_VERSION,
        },
    });

    updateUserPropsInStatist({
        appVersion: process.env.RELEASE_VERSION,
        screensize: `${window.screen.width}x${window.screen.height}`,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        connectionType: window.navigator?.connection?.effectiveType,
        isWeb: !isDesktopApp(),
    });
};
