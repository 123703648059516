import {useSelector} from 'react-redux';

import {useEffect} from 'react';

import {getCurrentUserId} from 'mattermost-redux/selectors/entities/common';
import {setFirebaseUserId} from 'features/firebase';

export const FirebaseUserSetter = () => {
    const currentUserId = useSelector(getCurrentUserId);

    useEffect(() => {
        if (currentUserId) {
            setFirebaseUserId(currentUserId);
        }
    }, [currentUserId]);

    return null;
};
