import {type ComponentProps} from 'react';

import InvitationModal from '../invitation_modal';

import {InviteMembersModal as InviteMembersModalNext} from './invite_members_modal';

export const InviteMembersModal = ({
    initialChannels,
    role,
    ...props
}: Pick<ComponentProps<typeof InviteMembersModalNext>, 'initialChannels' | 'role'> &
Omit<ComponentProps<typeof InvitationModal>, 'channelToInvite' | 'inviteAsGuest'>) => {
    return (
        <InvitationModal
            {...props}
            channelToInvite={initialChannels?.[0]}
            inviteAsGuest={role === 'team_guest'}
        />
    );
};
